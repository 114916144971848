/**
 * Login Page
 */
import { useEffect } from "react";
import { LoginRegContainer } from "../components/molecules/LoginRegContainer";
import { FirebaseAuth } from "../components/organisms/FirebaseLogin";

export const Landing = () => {
  useEffect(() => {
    document.title = "Sign in - PeppyPrep";
  }, []);

  return (
    <LoginRegContainer>
      <FirebaseAuth />
    </LoginRegContainer>
  );
};
