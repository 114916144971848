import { MinimalPlan } from "./user";

export type APIErrorResponse = {
  detail: string;
};

export function isError(test: any): test is APIErrorResponse {
  return test.detail !== undefined;
}

export type UserMeResponse = {
  id: string;
  first_name?: string;
  surname?: string;
  display_name: string;
  roles: string;
  owned_plans?: MinimalPlan[];
  model_version: number;
};

export function isUserMeResponse(test: any): test is UserMeResponse {
  return test.display_name !== undefined;
}

// Requests

export type UpdateUserRequest = {
  first_name?: string;
  surname?: string;
  display_name?: string;
};
