import { Typography, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Logo from "../../assets/pplogo_96.png";
import Background from "../../assets/patternjpg_noredborder.jpg";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export const HEADER_HEIGHT_PX = 95;
export const SMALL_HEADER_HEIGHT_PX = 60;

export interface HeaderProps {
  rightHandSide?: React.ReactNode;
  rightHandSideLargeScreen?: React.ReactNode;
  title?: string;
  forceSmallScreenMode?: boolean;
}

export const Header = (props: HeaderProps) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const {
    rightHandSide,
    rightHandSideLargeScreen,
    title = "PeppyPrep",
    forceSmallScreenMode = false,
  } = props;

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(open);
    };

  return (
    <div
      style={{
        display: "flex",
        padding: 16,
        alignItems: "center",
        backgroundImage: `url(${Background})`,
        backgroundPosition: "center",
        height: smallScreen ? SMALL_HEADER_HEIGHT_PX : HEADER_HEIGHT_PX,
      }}
    >
      <div
        style={{
          width: smallScreen ? 42 : 96,
          marginTop: 4,
        }}
      >
        <img src={Logo} alt="PeppyPrep" width={smallScreen ? 42 : 76} />
      </div>
      <Typography
        variant="h1"
        sx={{
          mr: "auto",
          ml: 1,
          fontWeight: 300,
          mb: smallScreen ? undefined : 1,
        }}
      >
        {title}
      </Typography>

      {!!rightHandSide &&
        (!smallScreen && !forceSmallScreenMode ? (
          rightHandSideLargeScreen
        ) : (
          <>
            <IconButton
              aria-label="menu"
              color="primary"
              onClick={toggleDrawer(!open)}
            >
              {open ? (
                <CloseRoundedIcon
                  fontSize={!smallScreen ? "large" : "medium"}
                />
              ) : (
                <MenuRoundedIcon fontSize={!smallScreen ? "large" : "medium"} />
              )}
            </IconButton>
            <Drawer
              anchor={"right"}
              open={open}
              onClose={toggleDrawer(false)}
              PaperProps={{
                sx: { width: smallScreen ? "60%" : "30%" },
              }}
            >
              <Box
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                {rightHandSide}
              </Box>
            </Drawer>
          </>
        ))}
    </div>
  );
};
