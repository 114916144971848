import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { createUser, login } from "./ApiUserService";
import { APIErrorResponse, UserMeResponse } from "../types/responses";
import { toastProps } from "../types/ui";
import { ApiUser } from "../types/user";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Auth stuff
const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = async (
  setAlertPayload: (alertPayload: toastProps) => void,
  setApiRequestLoading: (loading: boolean) => void,
  setApiUser: (apiUser: ApiUser) => void
) => {
  await signInWithPopup(auth, googleProvider)
    .then((res) => {
      const user = res.user;

      login(
        user,
        (data: UserMeResponse) => {
          // Save user data to local storage
          setApiUser(data);
          setApiRequestLoading(false);
        },
        (data: APIErrorResponse) => {
          // Create a user API side if we don't already have one
          if (data.detail.includes("USRCR3473")) {
            if (user.email) {
              const displayName = user.displayName;
              createUser(
                user,
                displayName ? displayName : user.email.split("@")[0],
                (data: UserMeResponse) => {
                  setApiUser(data);
                  setApiRequestLoading(false);
                },
                (data: APIErrorResponse) => {
                  logout();
                  deleteUser(res.user)
                    .then(() => {
                      // Peace out
                      console.log("User deleted");
                    })
                    .catch((error) => {
                      // Uh-oh
                      console.log("Could not delete user");
                    });
                  console.log(data);
                  setAlertPayload({
                    toastMessage: data.detail,
                    toastSeverity: "error",
                    showToast: true,
                  });

                  setApiRequestLoading(false);
                },
                // first_name
                displayName ? displayName.split(" ")[0] : "",
                // last_name
                displayName ? displayName.split(" ")[1] : ""
              );
            } else {
              // No user email, error
              setApiRequestLoading(false);
              setAlertPayload({
                toastMessage:
                  "There was an error signing in with your Google account",
                toastSeverity: "error",
                showToast: true,
              });
            }
          } else {
            // Not the error we expected
            setApiRequestLoading(false);
            setAlertPayload({
              toastMessage: data.detail,
              toastSeverity: "error",
              showToast: true,
            });
          }
        }
      );
    })
    .catch((error) => {
      let message = error.code;

      if (message === "auth/popup-closed-by-user") {
        // We don't want to report this error
        setApiRequestLoading(false);
        return;
      }

      setAlertPayload({
        toastMessage: message,
        toastSeverity: "error",
        showToast: true,
      });
    });
};

export const logInWithEmailAndPassword = async (
  email: string,
  password: string,
  setAlertPayload: (alertPayload: toastProps) => void,
  setApiUser: (apiUser: ApiUser) => void,
  setApiRequestLoading: (loading: boolean) => void
) => {
  await signInWithEmailAndPassword(auth, email, password)
    .then((res) => {
      const user = res.user;
      login(
        user,
        (data: UserMeResponse) => {
          // Save user data to local storage
          setApiUser(data);
          setApiRequestLoading(false);
        },
        (data: APIErrorResponse) => {
          setAlertPayload({
            toastMessage: data.detail,
            toastSeverity: "error",
            showToast: true,
          });
        }
      );
    })
    .catch((err) => {
      let message = "";

      if (err.code === "auth/user-not-found") {
        message = "Either the user does not exist or the password is incorrect";
      } else if (err.code === "auth/invalid-email") {
        message = "Please provide a valid email address";
      } else if (err.code === "auth/wrong-password") {
        message = "Please check your credentials and try again";
      } else {
        message = err.message;
      }
      setAlertPayload({
        toastMessage: message,
        toastSeverity: "error",
        showToast: true,
      });
    });
};

export const registerWithEmailAndPassword = async (
  name: string,
  email: string,
  password: string,
  setAlertPayload: (alertPayload: toastProps) => void,
  setAlertOpen: (open: boolean) => void,
  setApiRequestLoading: (loading: boolean) => void,
  setApiUser: (apiUser: ApiUser) => void,
  first_name: string = "",
  surname: string = ""
) => {
  await createUserWithEmailAndPassword(auth, email, password)
    .then((res) => {
      setApiRequestLoading(true);
      createUser(
        res.user,
        name,
        // Maybe save user data in local storage?
        (data: UserMeResponse) => {
          setApiUser(data);
          setApiRequestLoading(false);
        },
        (data: APIErrorResponse) => {
          setApiRequestLoading(false);
          setAlertPayload({
            toastMessage: data.detail,
            toastSeverity: "error",
            showToast: true,
          });

          deleteUser(res.user)
            .then(() => {
              // Peace out
              console.log("User deleted");
            })
            .catch((error) => {
              // Uh-oh
            });
          logout();
        },
        first_name,
        surname,
        true
      );
    })
    .catch((error) => {
      let message = error.code;

      if (message === "auth/invalid-email") {
        message = "Please enter a valid email address";
      } else if (message === "auth/weak-password") {
        message = "Your password should be greater than 6 characters";
      }

      setApiRequestLoading(false);
      setAlertPayload({
        toastMessage: message,
        toastSeverity: "error",
        showToast: true,
      });
    });
};

export const sendPasswordReset = async (email: string) => {
  await sendPasswordResetEmail(auth, email);
};

export const logout = () => {
  signOut(auth);
};
