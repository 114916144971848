import React from "react";
import "./index.css";
import { Route, Routes, BrowserRouter, useLocation } from "react-router-dom";
import { Landing } from "./pages/Landing";
import { Register } from "./pages/Register";
import { ResetPassword } from "./pages/ResetPassword";
import { Dashboard } from "./pages/Dashboard";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { Processing } from "./pages/Processing";
import ReactGA from "react-ga4";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { EULA } from "./pages/EULA";

const theme = createTheme({
  palette: {
    primary: {
      main: "#A3C14A",
    },
    secondary: {
      main: "#FBE2E8",
    },
    menu: {
      main: "#3EB23B",
    },
  },
  typography: {
    fontFamily: [
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: "24px",
      color: "#3F4726",
      fontWeight: 500,
    },
    h2: {
      fontSize: "20px",
      color: "#3F4726",
      fontWeight: 400,
    },
    h3: {
      fontSize: "18px",
      color: "#3F4726",
      fontWeight: 500,
    },
  },
});

theme.typography.h1 = {
  fontSize: "24px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
    fontWeight: 300,
  },
};

declare module "@mui/material/styles" {
  interface Palette {
    menu: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    menu?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    menu: true;
  }
}

function App() {
  const location = useLocation();

  React.useEffect(() => {
    const gaProp = process.env.REACT_APP_GA4_PROPERTY_ID;
    if (gaProp) {
      ReactGA.initialize(gaProp);
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
      });
    }
  }, [location]);

  return (
    <div className="App">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/register" element={<Register />} />
            <Route path="/reset" element={<ResetPassword />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/processing" element={<Processing />} />
            <Route path="/eula" element={<EULA />} />
          </Routes>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
