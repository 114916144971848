// React Drawer example stuff: https://mui.com/material-ui/react-drawer/
import * as React from "react";
import { auth } from "../../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Logout from "@mui/icons-material/Logout";
import {
  Header,
  HEADER_HEIGHT_PX,
  SMALL_HEADER_HEIGHT_PX,
} from "../organisms/Header";
import { useDispatch, useSelector } from "react-redux";

import { logout } from "../../services/firebase";
import { logout as APILogout } from "../../services/ApiUserService";
import { APIErrorResponse } from "../../types/responses";
import {
  resetToast,
  setApiRequestLoading,
  setToast,
} from "../../store/state/ui";
import { resetApiUser } from "../../store/state/user";
import { toastProps } from "../../types/ui";
import { Typography } from "@mui/material";

import Snackbar from "@mui/material/Snackbar";
import { Alert } from "../molecules/LoginRegContainer";
import { AppState } from "../../store/store";
import { IS_LOCAL, STORE_APP_URL } from "../../services/ApiCommon";

import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import InventoryIcon from "@mui/icons-material/Inventory";
import { useTheme, useMediaQuery } from "@mui/material";
import AccountMenu from "../organisms/AccountMenu";

export interface DashboardWrapperProps {
  pageTitle?: string;
  children?: React.ReactNode;
}

export default function DashboardWrapper(props: DashboardWrapperProps) {
  const { pageTitle, children } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [user, loading] = useAuthState(auth);
  const { apiUser } = useSelector((x: AppState) => x.userReducer);
  const { apiRequestLoading, toastMessage, toastSeverity, showToast } =
    useSelector((x: AppState) => x.uiReducer);
  // Set based on whether we're on mobile resolution or not
  // NOTE: We should use SwipableDrawer on mobile
  const [open, setOpen] = React.useState(true);

  const setAlertPayload = (alertPayload: toastProps) => {
    dispatch({ type: setToast, payload: alertPayload });
  };

  const handleAlertClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch({ type: resetToast });
  };

  const signOut = () => {
    dispatch({ type: setApiRequestLoading, payload: { loading: true } });
    APILogout(
      (data: APIErrorResponse) => {
        dispatch({ type: setApiRequestLoading, payload: { loading: false } });
        setAlertPayload({
          toastSeverity: "error",
          toastMessage: data.detail,
          showToast: true,
        });
      },
      // On logout success
      () => {
        dispatch({ type: resetApiUser });
        dispatch({ type: setApiRequestLoading, payload: { loading: false } });
      }
    );
    logout();
  };

  /**
   * Handle redirections for missing API User or Firebase user
   */
  React.useEffect(() => {
    if (
      ((loading || apiRequestLoading) && !apiUser.id) ||
      (user && !apiUser.id)
    ) {
      // If we're loading without a user, or we have a firebase user but no API user,
      // go to processing which will try populate the API user and bring us back.
      return navigate("/processing");
    } else if (apiRequestLoading && apiUser.id) {
      // A logged in user sent an API request, let them stick around
      return;
    } else if (!loading && !apiRequestLoading && !apiUser.id && !user?.uid) {
      // User probably logged out, we have no way of authenticating, send em home
      return navigate("/");
    }
  }, [user, loading, apiRequestLoading, apiUser]);

  const renderMenuItems = () => {
    return (
      <Box sx={{ overflow: "auto" }}>
        <List>
          <ListItem key="My Account" disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <Avatar sx={{ width: 24, height: 24 }} />
              </ListItemIcon>
              <ListItemText primary={"Account details"} />
            </ListItemButton>
          </ListItem>

          <ListItem key="purchases" disablePadding>
            <ListItemButton
              onClick={() =>
                (window.location.href = `${STORE_APP_URL}my-products`)
              }
            >
              <ListItemIcon>
                <InventoryIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={"My Products"} />
            </ListItemButton>
          </ListItem>

          {IS_LOCAL && (
            <>
              <ListItem key="alerts" disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <NotificationsActiveIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={"Updates & notifications"} />
                </ListItemButton>
              </ListItem>
            </>
          )}
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => signOut()}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={"Log out"} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          height: `${
            smallScreen ? SMALL_HEADER_HEIGHT_PX : HEADER_HEIGHT_PX
          }px`,
        }}
      >
        <Header
          title="My PeppyPrep"
          rightHandSide={<AccountMenu />}
          rightHandSideLargeScreen={<AccountMenu />}
          // forceSmallScreenMode
        />
      </AppBar>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          m: smallScreen ? 1 : 3,
          marginTop: `${
            smallScreen ? SMALL_HEADER_HEIGHT_PX + 8 : HEADER_HEIGHT_PX
          }px`,
        }}
      >
        {pageTitle && (
          <Typography variant="h2" variantMapping={{ h1: "h6" }}>
            {pageTitle}
          </Typography>
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>
          {children && children}
        </div>
      </Box>
      <Snackbar
        open={showToast}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {/* Pass alert props through to both this and children from top level pages, define stores there */}
        <Alert
          onClose={handleAlertClose}
          severity={toastSeverity}
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
