import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Background from "../../assets/patternjpg_noredborder.jpg";
import Logo from "../../assets/pplogo.png";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { SharedAlertProps } from "../../types/types";

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  }
);

export const LoginRegContainer = (props: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [alertProps, setAlertProps] = useState<SharedAlertProps>({
    message: "",
    severity: "info",
  });

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  return (
    <React.Fragment>
      <CssBaseline />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100vh",
          backgroundImage: `url(${Background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Container
          maxWidth="xs"
          sx={{ alignItems: "center", justifyContent: "center" }}
        >
          <Box
            sx={{
              bgcolor: "#c9e265",
              borderRadius: "8px",
              padding: "20px",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            justifyContent="center"
            alignItems="center"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a href="https://peppyprep.com/">
                <img
                  src={Logo}
                  alt="PeppyPrep"
                  style={{ width: "128px", height: "128px" }}
                />
              </a>
            </div>
            {props.children}
          </Box>
        </Container>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          {/* Pass alert props through to both this and children from top level pages, define stores there */}
          <Alert
            onClose={handleClose}
            severity={alertProps.severity}
            sx={{ width: "100%" }}
          >
            {alertProps.message}
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment>
  );
};
