import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import App from "./App";

const theme = createTheme({
  palette: {
    primary: {
      main: "#A3C14A",
    },
    secondary: {
      main: "#FBE2E8",
    },
    menu: {
      main: "#3EB23B",
    },
  },
  typography: {
    fontFamily: [
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: "24px",
      color: "#3F4726",
      fontWeight: 500,
    },
    h2: {
      fontSize: "20px",
      color: "#3F4726",
      fontWeight: 400,
    },
    h3: {
      fontSize: "18px",
      color: "#3F4726",
      fontWeight: 500,
    },
  },
});

theme.typography.h1 = {
  fontSize: "24px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
    fontWeight: 300,
  },
};

declare module "@mui/material/styles" {
  interface Palette {
    menu: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    menu?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    menu: true;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
