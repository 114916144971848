import { ApiUser } from "../types/user";

export const sleep = (seconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

export const userAvatarLetter = (user: ApiUser) => {
  if (user.first_name) {
    return user.first_name.substring(0, 1).toUpperCase();
  }
  return "...";
};

export const httpOnlyCookieExists = (cookiename: string) => {
  var d = new Date();
  d.setTime(d.getTime() + 1000);
  var expires = "expires=" + d.toUTCString();

  document.cookie = cookiename + "=new_value;path=/;" + expires;
  return document.cookie.indexOf(cookiename + "=") === -1;
};
