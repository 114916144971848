import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/store";
import { CMS_URL, IS_LOCAL, STORE_APP_URL } from "../../services/ApiCommon";
import Button from "@mui/material/Button";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import InventoryIcon from "@mui/icons-material/Inventory";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import { userAvatarLetter } from "../../helpers/utils";
import { resetApiUser } from "../../store/state/user";

import { logout } from "../../services/firebase";
import { logout as APILogout } from "../../services/ApiUserService";
import { setApiRequestLoading, setToast } from "../../store/state/ui";
import { APIErrorResponse } from "../../types/responses";
import { toastProps } from "../../types/ui";
import { SMALL_HEADER_HEIGHT_PX, HEADER_HEIGHT_PX } from "./Header";

import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";

export default function AccountMenu() {
  /**
   * TODO:  Menu button texts, icons and on click actions as variables we can share
   * between desktop and mobile variants.
   */
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const open = Boolean(anchorEl);
  const { apiUser } = useSelector((x: AppState) => x.userReducer);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setAlertPayload = (alertPayload: toastProps) => {
    dispatch({ type: setToast, payload: alertPayload });
  };

  const signOut = () => {
    dispatch({ type: setApiRequestLoading, payload: { loading: true } });
    logout();
    APILogout(
      (data: APIErrorResponse) => {
        dispatch({ type: setApiRequestLoading, payload: { loading: false } });
        setAlertPayload({
          toastSeverity: "error",
          toastMessage: data.detail,
          showToast: true,
        });
      },
      // On logout success
      () => {
        dispatch({ type: resetApiUser });
        dispatch({ type: setApiRequestLoading, payload: { loading: false } });
      }
    );
    navigate("/processing");
  };

  if (!smallScreen) {
    return (
      <React.Fragment>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            marginRight: "8px",
          }}
        >
          <Button
            variant="text"
            sx={{ textTransform: "none" }}
            onClick={() => (window.location.href = `${CMS_URL}?pp_auth=true`)}
            color="menu"
          >
            Home
          </Button>
          <Button
            variant="text"
            sx={{ textTransform: "none" }}
            onClick={() => (window.location.href = `${STORE_APP_URL}`)}
            color="menu"
          >
            Shop
          </Button>
          <Button
            variant="text"
            sx={{ textTransform: "none" }}
            onClick={() =>
              (window.location.href = `${STORE_APP_URL}my-products`)
            }
            color="menu"
          >
            My Products
          </Button>
          {!!apiUser.id && (
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="large"
                sx={{ ml: 1 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar
                  sx={{ width: 32, height: 32, backgroundColor: "#BBE822" }}
                >
                  {userAvatarLetter(apiUser)}
                </Avatar>
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {/* <MenuItem>
            <Avatar /> Profile
          </MenuItem> */}
          {/* <MenuItem> */}
          <Typography variant="subtitle2" sx={{ padding: "8px 16px" }}>
            Good to see you{" "}
            {!!apiUser.first_name ? apiUser.first_name : apiUser.display_name}
          </Typography>
          {/* </MenuItem> */}
          <MenuItem onClick={() => navigate("/dashboard")}>
            <Avatar style={{ backgroundColor: "#BBE822" }} /> My account
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => signOut()}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }

  return (
    <List>
      <div
        style={{
          marginTop: `${
            smallScreen ? SMALL_HEADER_HEIGHT_PX + 8 : HEADER_HEIGHT_PX + 8
          }px`,
        }}
      ></div>
      {!!apiUser.id && (
        <>
          <Typography variant="subtitle2" sx={{ padding: "8px 16px" }}>
            Good to see you{" "}
            {!!apiUser.first_name ? apiUser.first_name : apiUser.display_name}
          </Typography>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/dashboard")}>
              <ListItemIcon>
                <Avatar
                  style={{ backgroundColor: "#BBE822", width: 24, height: 24 }}
                />
              </ListItemIcon>
              <ListItemText primary={"My account"} />
            </ListItemButton>
          </ListItem>
          {IS_LOCAL && (
            <>
              <ListItem key="alerts" disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <NotificationsActiveIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={"Updates & notifications"} />
                </ListItemButton>
              </ListItem>
            </>
          )}
        </>
      )}

      <Divider sx={{ margin: "16px 0" }} />

      <ListItem disablePadding>
        <ListItemButton
          onClick={() => (window.location.href = `${CMS_URL}?pp_auth=true`)}
        >
          <ListItemIcon>
            <HomeRoundedIcon />
          </ListItemIcon>
          <ListItemText primary={"Home"} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => (window.location.href = `${STORE_APP_URL}`)}
        >
          <ListItemIcon>
            <ShoppingCartRoundedIcon />
          </ListItemIcon>
          <ListItemText primary={"Shop"} />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton
          onClick={() => (window.location.href = `${STORE_APP_URL}my-products`)}
        >
          <ListItemIcon>
            <InventoryIcon />
          </ListItemIcon>
          <ListItemText primary={"My Products"} />
        </ListItemButton>
      </ListItem>

      <Divider sx={{ margin: "16px 0" }} />

      <ListItem disablePadding>
        <ListItemButton onClick={() => signOut()}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={"Logout"} />
        </ListItemButton>
      </ListItem>
    </List>
  );
}
