import { createSlice } from "@reduxjs/toolkit";
import { User } from "../../types/user";

const initialState: User = {
  apiUser: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setApiUser: (state, action) => {
      const {
        id,
        display_name,
        email,
        roles,
        owned_plans,
        first_name,
        surname,
        eula_agreed,
      } = action.payload;
      state.apiUser.id = id;
      state.apiUser.display_name = display_name;
      state.apiUser.email = email;
      state.apiUser.roles = roles;
      state.apiUser.owned_plans = owned_plans;
      state.apiUser.first_name = first_name;
      state.apiUser.surname = surname;
      state.apiUser.eula_agreed = eula_agreed;
    },
    resetApiUser: (state) => {
      state.apiUser = {};
    },
  },
});

export const { setApiUser, resetApiUser } = userSlice.actions;
