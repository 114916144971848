import {
  APIErrorResponse,
  isUserMeResponse,
  UpdateUserRequest,
  UserMeResponse,
} from "../types/responses";
import {
  API_BASE_URL,
  API_USERS_ENDPOINT,
  getApiRequestHeaders,
  returnError,
  fetchRefresh,
} from "./ApiCommon";
import { User as FirebaseUser } from "firebase/auth";
import { getUserToken } from "../helpers/auth";
import { sleep } from "../helpers/utils";

export const getUserMe = async (
  onSuccess: (data: UserMeResponse) => void,
  onError: (data: APIErrorResponse) => void
): Promise<void> => {
  const headers = await getApiRequestHeaders();

  const request = {
    method: "GET",
    headers: headers,
    credentials: "include",
  } as RequestInit;

  fetchRefresh(API_BASE_URL + API_USERS_ENDPOINT + "me", request)
    .then((response) => response.json())
    .then((data) => {
      if (isUserMeResponse(data)) {
        onSuccess(data);
      } else {
        const return_error = returnError(data);
        onError(return_error);
      }
    })
    .catch((error) => {
      const return_error = returnError(error);
      onError(return_error);
    });
};

export const createUser = async (
  user: FirebaseUser,
  display_name: string,
  onSuccess: (data: UserMeResponse) => void,
  onError: (data: APIErrorResponse) => void,
  first_name: string = "",
  surname: string = "",
  eula_agreed: boolean = false
): Promise<void> => {
  const headers = await getApiRequestHeaders(user);
  const token = await getUserToken(user);

  const request = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      firebase_token: token,
      display_name: display_name,
      first_name: first_name,
      surname: surname,
      eula_agreed: eula_agreed,
    }),
    credentials: "include",
  } as RequestInit;

  fetch(API_BASE_URL + API_USERS_ENDPOINT + "create", request)
    .then((response) => response.json())
    .then((data) => {
      if (isUserMeResponse(data)) {
        onSuccess(data);
      } else {
        const return_error = returnError(data);
        onError(return_error);
      }
    })
    .catch((error) => {
      const return_error = returnError(error);
      onError(return_error);
    });
};

// Don't bother passing what you can't update
export const updateUser = async (
  updatePayload: UpdateUserRequest,
  onSuccess: (data: UserMeResponse) => void,
  onError: (data: APIErrorResponse) => void
): Promise<void> => {
  // Don't incur API request for empty payload
  if (Object.keys(updatePayload).length === 0) {
    onError({ detail: "Nothing to update." });
    return;
  }

  const headers = await getApiRequestHeaders();

  const request = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(updatePayload),
    credentials: "include",
  } as RequestInit;

  fetchRefresh(API_BASE_URL + API_USERS_ENDPOINT + "me/update", request)
    .then((response) => response.json())
    .then((data) => {
      if (isUserMeResponse(data)) {
        onSuccess(data);
      } else {
        const return_error = returnError(data);
        onError(return_error);
      }
    })
    .catch((error) => {
      const return_error = returnError(error);
      onError(return_error);
    });
};

export const login = async (
  user: FirebaseUser,
  onSuccess: (data: UserMeResponse) => void,
  onError: (data: APIErrorResponse) => void
): Promise<void> => {
  const headers = await getApiRequestHeaders(user);

  const request = {
    method: "POST",
    headers: headers,
    credentials: "include",
  } as RequestInit;

  fetch(API_BASE_URL + API_USERS_ENDPOINT + "login", request)
    .then((response) => response.json())
    .then((data) => {
      if (isUserMeResponse(data)) {
        onSuccess(data);
      } else {
        const return_error = returnError(data);
        onError(return_error);
      }
    })
    .catch((error) => {
      const return_error = returnError(error);
      onError(return_error);
    });
};

export const logout = async (
  onError: (data: APIErrorResponse) => void,
  onSuccess?: () => void
): Promise<void> => {
  const headers = await getApiRequestHeaders();

  const request = {
    method: "GET",
    headers: headers,
    credentials: "include",
  } as RequestInit;

  fetch(API_BASE_URL + API_USERS_ENDPOINT + "logout", request)
    .then((response) => response.json())
    .then(async (data) => {
      // Sleep until the cookie expires
      await sleep(2);
      onSuccess && onSuccess();
    })
    .catch((error) => {
      const return_error = returnError(error);
      onError(return_error);
    });
};

export const acceptEula = async (
  onSuccess: (data: UserMeResponse) => void,
  onError: (data: APIErrorResponse) => void
) => {
  const headers = await getApiRequestHeaders();

  const request = {
    method: "POST",
    headers: headers,
    credentials: "include",
  } as RequestInit;

  fetchRefresh(API_BASE_URL + API_USERS_ENDPOINT + "accept-eula", request)
    .then((response) => response.json())
    .then(async (data) => {
      if (isUserMeResponse(data)) {
        onSuccess(data);
      } else {
        const return_error = returnError(data);
        onError(return_error);
      }
    })
    .catch((error) => {
      const return_error = returnError(error);
      onError(return_error);
    });
};

export const debugDeleteProducts = async () => {
  const headers = await getApiRequestHeaders();

  const request = {
    method: "GET",
    headers: headers,
    credentials: "include",
  } as RequestInit;

  fetch(API_BASE_URL + "shop/debug/delete-my-products", request)
    .then((response) => response.json())
    .then(async (data) => {
      window.location.reload();
    })
    .catch((error) => {});
};
