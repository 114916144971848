import { useEffect } from "react";
import { LoginRegContainer } from "../components/molecules/LoginRegContainer";
import { ResetPass } from "../components/organisms/ResetPass";

export const ResetPassword = () => {
  useEffect(() => {
    document.title = "Reset Password - PeppyPrep";
  }, []);

  return (
    <LoginRegContainer>
      <ResetPass />
    </LoginRegContainer>
  );
};
