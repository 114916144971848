import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UiState } from "../../types/ui";
const initialState: UiState = {
  // either info, error, warning, or success
  toastSeverity: "info",
  toastMessage: null,
  showToast: false,
  apiRequestLoading: false,
  redirectUrl: null,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState: initialState,
  reducers: {
    setToast: (state, action) => {
      const { toastMessage, toastSeverity, showToast } = action.payload;
      state.toastSeverity = toastSeverity;
      state.toastMessage = toastMessage;
      state.showToast = showToast;
    },
    resetToast: (state) => {
      state.showToast = false;
      state.toastMessage = null;
    },
    setApiRequestLoading: (state, action) => {
      const { loading } = action.payload;
      state.apiRequestLoading = loading;
    },
    setRedirectUrl: (state, action) => {
      state.redirectUrl = action.payload;
    },
    clearRedirectUrl: (state) => {
      state.redirectUrl = null;
    },
  },
});

export const {
  setToast,
  resetToast,
  setApiRequestLoading,
  setRedirectUrl,
  clearRedirectUrl,
} = uiSlice.actions;
