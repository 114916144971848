import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  auth,
  logInWithEmailAndPassword,
  signInWithGoogle,
  logout as firebaseLogout,
} from "../../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./FirebaseAuth.css";
import { CircularProgress, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import GoogleIcon from "@mui/icons-material/Google";
import { AlertComponent } from "../molecules/AlertComponent";
import { useDispatch, useSelector } from "react-redux";
import { toastProps, UiState } from "../../types/ui";
import { AppState } from "../../store/store";
import {
  setApiRequestLoading as setApiLoading,
  setRedirectUrl,
  setToast,
} from "../../store/state/ui";
import { resetApiUser, setApiUser } from "../../store/state/user";
import { ApiUser } from "../../types/user";
import { logout as apiLogout } from "../../services/ApiUserService";
import { APIErrorResponse } from "../../types/responses";
import { httpOnlyCookieExists } from "../../helpers/utils";

export const FirebaseAuth = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [alertOpen, setAlertOpen] = useState(false);
  const uiState: UiState = useSelector((x: AppState) => x.uiReducer);
  const [searchParams, setSearchParams] = useSearchParams();

  const setAlertPayload = (alertPayload: toastProps) => {
    dispatch({ type: setToast, payload: alertPayload });
  };
  const setApiRequestLoading = (loading: boolean) => {
    dispatch({ type: setApiLoading, payload: { loading: loading } });
  };
  const doSetApiUser = (apiUser: ApiUser) => {
    dispatch({ type: setApiUser, payload: apiUser });
  };

  const navigate = useNavigate();
  const redirectUrl = searchParams.get("redirect_url");
  const logout = searchParams.get("logout");

  useEffect(() => {
    // Action logout with optional redirect
    if (!!logout && logout === "true") {
      dispatch({ type: setApiLoading, payload: { loading: true } });
      apiLogout(
        (data: APIErrorResponse) => {
          dispatch({ type: setApiLoading, payload: { loading: false } });
        },
        () => {
          firebaseLogout();
          dispatch({ type: setApiLoading, payload: { loading: false } });
          dispatch({ type: resetApiUser });
          if (!!redirectUrl) window.location.href = redirectUrl;
        }
      );
    } else if (!logout && !!redirectUrl) {
      dispatch({ type: setRedirectUrl, payload: redirectUrl });
    }
  }, []);

  useEffect(() => {
    if (loading || uiState.apiRequestLoading || logout) return;

    // If we have a firebase user, we can do a request for
    // PP user data from the processing page
    if (!!redirectUrl) {
      dispatch({ type: setRedirectUrl, payload: redirectUrl });
    }
    if (user) navigate("/processing");
  }, [user, loading, uiState.apiRequestLoading]);

  return (
    <div className="login">
      <div className="login__container">
        <TextField
          id="email"
          variant="filled"
          label={"Email"}
          InputProps={{
            sx: {
              backgroundColor: "#fff",
            },
          }}
          sx={{ width: "300px", marginBottom: "8px" }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={uiState.apiRequestLoading}
        />

        <TextField
          id="password"
          variant="filled"
          label={"Password"}
          type="password"
          InputProps={{
            sx: {
              backgroundColor: "#fff",
            },
          }}
          sx={{ width: "300px", marginBottom: "8px" }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={uiState.apiRequestLoading}
        />

        <Button
          variant="contained"
          onClick={() =>
            logInWithEmailAndPassword(
              email,
              password,
              setAlertPayload,
              doSetApiUser,
              setApiRequestLoading
            )
          }
          sx={{ marginBottom: "8px" }}
          disabled={uiState.apiRequestLoading}
        >
          Login
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setApiRequestLoading(true);
            signInWithGoogle(
              setAlertPayload,
              setApiRequestLoading,
              doSetApiUser
            );
          }}
          startIcon={<GoogleIcon />}
          sx={{ marginBottom: "24px" }}
          disabled={uiState.apiRequestLoading}
        >
          Login/Register with Google
        </Button>

        <div style={{ width: "40px", height: "40px", margin: "0 auto" }}>
          {uiState.apiRequestLoading && (
            <CircularProgress color="primary" sx={{ margin: "0 auto" }} />
          )}
        </div>
        <Typography variant="subtitle2">
          <Link to="/reset">Forgot Password</Link>
        </Typography>
        <Typography variant="subtitle2">
          Don't have an account? <Link to="/register">Register</Link> now.
        </Typography>
      </div>
      <AlertComponent
        open={uiState.showToast}
        setOpen={setAlertOpen}
        alertProps={uiState}
      />
    </div>
  );
};
