import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { SharedAlertProps } from "../../types/types";
import { toastProps, UiState } from "../../types/ui";
import { useDispatch } from "react-redux";
import { resetToast, setToast } from "../../store/state/ui";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AlertComponent = (props: {
  alertProps: toastProps;
  open: boolean;
  setOpen?: (open: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const { alertProps, open, setOpen } = props;

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch({ type: resetToast });
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <Alert
        onClose={() => handleClose()}
        severity={alertProps.toastSeverity}
        sx={{ width: "100%" }}
      >
        {alertProps.toastMessage}
      </Alert>
    </Snackbar>
  );
};
