// https://redux.js.org/introduction/getting-started
// https://redux.js.org/recipes/usage-with-typescript

import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { IS_LOCAL } from "../services/ApiCommon";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { User } from "../types/user";
import { UiState } from "../types/ui";
import { userSlice } from "./state/user";
import { uiSlice } from "./state/ui";

const persistConfig = {
  key: "root",
  storage,
};

export interface AppState {
  userReducer: User;
  uiReducer: UiState;
}

const reducers = combineReducers({
  userReducer: userSlice.reducer,
  uiReducer: uiSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  devTools: IS_LOCAL,
});
