import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { auth } from "../services/firebase";
import {
  reauthenticateWithCredential,
  updatePassword,
  EmailAuthProvider,
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store/store";
import { debugDeleteProducts, updateUser } from "../services/ApiUserService";
import {
  APIErrorResponse,
  UpdateUserRequest,
  UserMeResponse,
} from "../types/responses";
import { setApiUser } from "../store/state/user";
import { setApiRequestLoading, setToast } from "../store/state/ui";
import { toastProps, UiState } from "../types/ui";
import { TextField, Typography } from "@mui/material";
import DashboardWrapper from "../components/templates/DashboardWrapper";
import Divider from "@mui/material/Divider";
import { getAuthProviders } from "../helpers/auth";
import { STORE_APP_URL } from "../services/ApiCommon";

export const Dashboard = () => {
  const dispatch = useDispatch();
  const [user, loading] = useAuthState(auth);
  const { apiUser } = useSelector((x: AppState) => x.userReducer);
  const { apiRequestLoading } = useSelector((x: AppState) => x.uiReducer);
  const uiState: UiState = useSelector((x: AppState) => x.uiReducer);

  const [email, setEmail] = useState<string>(
    user?.email || apiUser?.email || ""
  );
  const [firstName, setFirstName] = useState<string | undefined>(
    apiUser?.first_name
  );
  const [lastName, setLastName] = useState<string | undefined>(
    apiUser?.surname
  );
  const [displayName, setDisplayName] = useState<string | undefined>(
    apiUser?.display_name
  );

  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");

  const [oldPwError, setOldPwError] = useState(false);
  const [newPwError, setNewPwError] = useState(false);
  const [confNewPwError, setConfNewPwError] = useState(false);

  const showDebugControls =
    (apiUser.roles && apiUser.roles.includes("admin")) ||
    (STORE_APP_URL && STORE_APP_URL.includes("staging"));

  useEffect(() => {
    document.title = "Account details - PeppyPrep";
  }, []);

  useEffect(() => {
    if (!email && apiUser?.email) setEmail(apiUser.email);
  }, [apiUser.email]);

  const someRequestLoading = loading || apiRequestLoading;

  const authProviders = getAuthProviders(user);

  /**
   * Checks if changes have been made to any form fields.
   *
   * @returns true if changes made, false otherwise
   */
  const changesMade = () => {
    if (
      (!apiRequestLoading && firstName !== apiUser?.first_name) ||
      lastName !== apiUser?.surname ||
      displayName !== apiUser?.display_name
    ) {
      return true;
    }
    return false;
  };

  const passwordChangesMade = () => {
    if (oldPassword !== "" || newPassword !== "" || confirmNewPassword !== "") {
      return true;
    }
    return false;
  };

  const setAlertPayload = (alertPayload: toastProps) => {
    dispatch({ type: setToast, payload: alertPayload });
  };

  const onClickSave = () => {
    const updateUserRequest: UpdateUserRequest = {
      first_name: firstName,
      surname: lastName,
      display_name: displayName,
    };

    dispatch({ type: setApiRequestLoading, payload: { loading: true } });

    updateUser(
      updateUserRequest,
      (data: UserMeResponse) => {
        dispatch({ type: setApiUser, payload: data });
        dispatch({ type: setApiRequestLoading, payload: { loading: false } });
        setAlertPayload({
          toastMessage:
            "Details updated successfully, go forth and prep peppily!",
          toastSeverity: "success",
          showToast: true,
        });
      },
      (error: APIErrorResponse) => {
        setAlertPayload({
          toastMessage: error.detail,
          toastSeverity: "error",
          showToast: true,
        });
        dispatch({ type: setApiRequestLoading, payload: { loading: false } });
      }
    );
  };

  const onClickPasswordUpdate = () => {
    if (!user || !user.email) {
      return;
    }
    // Reset
    setOldPwError(false);
    setNewPwError(false);
    setConfNewPwError(false);

    // Validate
    if (!oldPassword) {
      setOldPwError(true);
      setAlertPayload({
        toastMessage: "Please enter your current password",
        toastSeverity: "error",
        showToast: true,
      });
      return;
    } else if (!newPassword && !confirmNewPassword) {
      !newPassword && setNewPwError(true);
      !confirmNewPassword && setConfNewPwError(true);

      setAlertPayload({
        toastMessage: "All password fields must be filled",
        toastSeverity: "error",
        showToast: true,
      });
      return;
    } else if (newPassword !== confirmNewPassword) {
      newPassword && setNewPwError(true);
      confirmNewPassword && setConfNewPwError(true);

      setAlertPayload({
        toastMessage: "Passwords do not match",
        toastSeverity: "error",
        showToast: true,
      });
      return;
    }

    // Good to go
    const credential = EmailAuthProvider.credential(user.email, oldPassword);

    reauthenticateWithCredential(user, credential)
      .then(() => {
        updatePassword(user, newPassword)
          .then(() => {
            setAlertPayload({
              toastMessage: "Password updated",
              toastSeverity: "success",
              showToast: true,
            });
            setOldPassword("");
            setNewPassword("");
            setConfirmNewPassword("");
          })
          .catch((error) => {
            setAlertPayload({
              toastMessage:
                "Could not set new password, please make sure your new password has more than 6 characters.",
              toastSeverity: "error",
              showToast: true,
            });
            console.log(error.message);
          });
      })
      .catch((error) => {
        setAlertPayload({
          toastMessage:
            "Could not re-authenticate, please check your current password.",
          toastSeverity: "error",
          showToast: true,
        });
        console.log(error.message);
      });
  };

  return (
    <DashboardWrapper pageTitle="Account details">
      <>
        <Divider variant="middle" sx={{ margin: "16px 0" }} />
        <TextField
          id="firstName"
          label={"First name"}
          InputProps={{
            sx: {
              backgroundColor: "#fff",
            },
          }}
          sx={{ width: "300px", marginBottom: "8px", marginTop: "16px" }}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          disabled={uiState.apiRequestLoading}
        />

        <TextField
          id="surname"
          label={"Last name"}
          InputProps={{
            sx: {
              backgroundColor: "#fff",
            },
          }}
          sx={{ width: "300px", marginBottom: "8px", marginTop: "16px" }}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          disabled={uiState.apiRequestLoading}
        />

        {/* <TextField
            id="displayName"
            label={'Display Name'}
            InputProps={{
                sx: {
                    backgroundColor: '#fff',
                }
            }}
            sx={{ width: '300px', marginBottom: '8px', marginTop: '16px' }}
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            disabled={uiState.apiRequestLoading}
          /> */}

        <TextField
          id="email"
          label={"Email"}
          InputProps={{
            sx: {
              backgroundColor: "#fff",
            },
          }}
          sx={{ width: "300px", marginBottom: "8px", marginTop: "16px" }}
          value={email}
          // onChange={(e) => setEmail(e.target.value)}
          disabled
        />

        <Button
          variant="contained"
          onClick={() => onClickSave()}
          size="medium"
          sx={{ maxWidth: "150px", width: "100%", marginTop: "8px" }}
          disabled={uiState?.apiRequestLoading || !changesMade()}
        >
          Save
        </Button>

        <Divider variant="middle" sx={{ margin: "16px 0" }} />

        <Typography variant="h3">Password</Typography>
        {authProviders && !authProviders.includes("password") ? (
          authProviders.length > 1 ? (
            <Typography sx={{ margin: "16px 0" }}>
              You can't change your password because you log in with one or more
              of the following accounts: {authProviders.join(", ")}.
            </Typography>
          ) : (
            <Typography sx={{ margin: "16px 0" }}>
              You can't change your password because you logged in with your{" "}
              {authProviders[0]} account.
            </Typography>
          )
        ) : (
          <>
            <TextField
              id="password"
              label={"Current Password"}
              type="password"
              error={oldPwError}
              InputProps={{
                sx: {
                  backgroundColor: "#fff",
                },
              }}
              sx={{ width: "300px", marginBottom: "8px", marginTop: "16px" }}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <TextField
              id="new_password"
              label={"New Password"}
              type="password"
              error={newPwError}
              InputProps={{
                sx: {
                  backgroundColor: "#fff",
                },
              }}
              sx={{ width: "300px", marginBottom: "8px", marginTop: "16px" }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              id="conf_new_password"
              label={"Confirm new Password"}
              type="password"
              error={confNewPwError}
              InputProps={{
                sx: {
                  backgroundColor: "#fff",
                },
              }}
              sx={{ width: "300px", marginBottom: "8px", marginTop: "16px" }}
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
            <Button
              variant="contained"
              onClick={() => onClickPasswordUpdate()}
              size="medium"
              sx={{ maxWidth: "150px", width: "100%", marginTop: "8px" }}
              disabled={someRequestLoading || !passwordChangesMade()}
            >
              Update
            </Button>
          </>
        )}

        <Divider variant="middle" sx={{ margin: "16px 0" }} />

        {showDebugControls && (
          <>
            <Typography variant="h3">Testing tools</Typography>
            <p>
              Always visible on the staging site, only visible on production for
              administrators.
            </p>
            <Button
              variant="contained"
              onClick={() => debugDeleteProducts()}
              size="medium"
              sx={{ maxWidth: "300px", width: "100%", marginTop: "8px" }}
              disabled={someRequestLoading}
            >
              Delete all products
            </Button>
          </>
        )}
      </>
    </DashboardWrapper>
  );
};
