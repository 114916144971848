import { Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth, sendPasswordReset } from "../../services/firebase";
import { AppState } from "../../store/store";
import { UiState } from "../../types/ui";
import CircularProgress from "@mui/material/CircularProgress";
import "./ResetPass.css";

export const ResetPass = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [user, loading, error] = useAuthState(auth);
  const uiState: UiState = useSelector((x: AppState) => x.uiReducer);

  useEffect(() => {
    if (loading) return;
    if (user) return navigate("/dashboard");
  }, [user, loading]);

  const validateAndSend = () => {
    setEmailError(false);
    if (!email) {
      setEmailError(true);
      return;
    }
    sendPasswordReset(email);
  };

  return (
    <div className="reset">
      <div className="reset__container">
        <TextField
          id="email"
          variant="filled"
          label={"Email"}
          InputProps={{
            sx: {
              backgroundColor: "#fff",
            },
          }}
          sx={{ width: "300px", marginBottom: "8px" }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={uiState.apiRequestLoading}
          error={emailError && !email}
          required
        />
        <Button
          variant="contained"
          onClick={() => validateAndSend()}
          sx={{ marginBottom: "8px" }}
          disabled={uiState.apiRequestLoading}
        >
          Send password reset email
        </Button>
        {/* <button
          className="reset__btn"
          onClick={() => sendPasswordReset(email)}
        >
          Send password reset email
        </button> */}
        <div style={{ width: "40px", height: "40px", margin: "0 auto" }}>
          {uiState.apiRequestLoading && (
            <CircularProgress color="primary" sx={{ margin: "0 auto" }} />
          )}
        </div>
        <div>
          Don't have an account? <Link to="/register">Register</Link> now.
        </div>
      </div>
    </div>
  );
};
