import { Button, Typography } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { LoginRegContainer } from "../components/molecules/LoginRegContainer";
import { useEffect, useState } from "react";
import { CMS_URL } from "../services/ApiCommon";
import { acceptEula } from "../services/ApiUserService";
import { APIErrorResponse, UserMeResponse } from "../types/responses";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setApiUser } from "../store/state/user";
import {
  setApiRequestLoading,
  setRedirectUrl,
  setToast,
} from "../store/state/ui";

export const eulaURL = `${CMS_URL}eula`;

export const EULA = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [accepted, setAccepted] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect_url");

  // Set redirect url, we may redirect here from store
  useEffect(() => {
    if (!!redirectUrl) {
      dispatch({ type: setRedirectUrl, payload: redirectUrl });
    }
  }, [searchParams]);

  useEffect(() => {
    document.title = "EULA - PeppyPrep";
  }, []);

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccepted(event.target.checked);
  };

  const onSubmit = () => {
    // Send post request to accept EULA
    dispatch({ type: setApiRequestLoading, payload: { loading: true } });
    acceptEula(
      (data: UserMeResponse) => {
        dispatch({ type: setApiUser, payload: data });
        dispatch({ type: setApiRequestLoading, payload: { loading: false } });
        navigate("/processing");
      },
      (data: APIErrorResponse) => {
        dispatch({ type: setApiRequestLoading, payload: { loading: false } });
        dispatch({
          type: setToast,
          payload: {
            toastMessage:
              "It looks like something went wrong, please try again and contact support if the problem persists.",
            toastSeverity: "error",
            showToast: true,
          },
        });
      }
    );
  };

  return (
    <LoginRegContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h2">End User License Agreement</Typography>
        <p>
          To use PeppyPrep products and services, you'll need to read through
          and accept our{" "}
          <a href={eulaURL} target="_blank">
            End User License Agreement
          </a>
          .
        </p>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                checked={accepted}
                onChange={onCheckboxChange}
              />
            }
            label={
              <Typography variant="subtitle2">
                I have read and accepted the PeppyPrep End User License
                Agreement
              </Typography>
            }
            sx={{ marginRight: 0 }}
          />
        </FormGroup>
        <Button
          variant="contained"
          disabled={!accepted}
          sx={{ marginTop: 2 }}
          onClick={() => onSubmit()}
        >
          Continue
        </Button>
      </div>
    </LoginRegContainer>
  );
};
