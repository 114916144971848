import { FirebaseRegister } from "../components/organisms/FirebaseRegister";
import { LoginRegContainer } from "../components/molecules/LoginRegContainer";
import { useEffect } from "react";

export const Register = () => {
  useEffect(() => {
    document.title = "Register - PeppyPrep";
  }, []);

  return (
    <LoginRegContainer>
      <FirebaseRegister />
    </LoginRegContainer>
  );
};
