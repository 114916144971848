import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { auth, registerWithEmailAndPassword } from "../../services/firebase";
import "./FirebaseRegister.css";
import { AlertComponent } from "../molecules/AlertComponent";
import { toastProps, UiState } from "../../types/ui";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/store";
import {
  setApiRequestLoading as setApiLoading,
  setToast,
} from "../../store/state/ui";
import { TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { setApiUser } from "../../store/state/user";
import { ApiUser } from "../../types/user";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { eulaURL } from "../../pages/EULA";

export const FirebaseRegister = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  // Optional fields
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");

  const [user, loading, error] = useAuthState(auth);
  const [oldAlertOpen, setAlertOpen] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [eulaAccepted, setEulaAccepted] = useState(false);
  const [eulaError, setEulaError] = useState(false);

  const uiState: UiState = useSelector((x: AppState) => x.uiReducer);
  const apiUser = useSelector((x: AppState) => x.userReducer.apiUser);

  const setAlertPayload = (alertPayload: toastProps) => {
    dispatch({ type: setToast, payload: alertPayload });
  };
  const setApiRequestLoading = (is_loading: boolean) => {
    dispatch({ type: setApiLoading, payload: { loading: is_loading } });
  };
  const doSetApiUser = (apiUser: ApiUser) => {
    dispatch({ type: setApiUser, payload: apiUser });
  };

  const navigate = useNavigate();

  const register = () => {
    // Reset errors
    setNameError(false);
    setEmailError(false);
    setPasswordError(false);
    setEulaError(false);

    if (!name) setNameError(true);
    if (!email) setEmailError(true);
    if (!password) setPasswordError(true);
    if (!eulaAccepted) setEulaError(true);

    if (!fName || !lName || !email || !password) {
      setAlertPayload({
        toastMessage: "Some required fields are missing",
        toastSeverity: "error",
        showToast: true,
      });
      return;
    }

    if (!eulaAccepted) {
      setAlertPayload({
        toastMessage: "Please read and accept the end user license agreement.",
        toastSeverity: "error",
        showToast: true,
      });
      return;
    }

    setApiRequestLoading(true);
    registerWithEmailAndPassword(
      name,
      email,
      password,
      setAlertPayload,
      setAlertOpen,
      setApiRequestLoading,
      doSetApiUser,
      fName,
      lName
    );
  };

  useEffect(() => {
    if (loading) return;
    if (user && apiUser.id && !uiState.apiRequestLoading)
      navigate("/processing");
  }, [user, loading, apiUser]);

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEulaAccepted(event.target.checked);
  };

  return (
    <div className="register">
      <div className="register__container">
        {/* <TextField
          id="display_name"
          variant="filled"
          label={'Display name'}
          InputProps={{
              sx: {
                  backgroundColor: '#fff',
              }
          }}
          sx={{ width: '300px', marginBottom: '8px' }}
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={uiState.apiRequestLoading}
          error={nameError && !name}
          required
        /> */}
        <TextField
          id="fName"
          variant="filled"
          label={"First name"}
          InputProps={{
            sx: {
              backgroundColor: "#fff",
            },
          }}
          sx={{ width: "300px", marginBottom: "8px" }}
          value={fName}
          onChange={(e) => setFName(e.target.value)}
          disabled={uiState.apiRequestLoading}
          error={nameError && !fName}
          required
        />
        <TextField
          id="lName"
          variant="filled"
          label={"Last name"}
          InputProps={{
            sx: {
              backgroundColor: "#fff",
            },
          }}
          sx={{ width: "300px", marginBottom: "8px" }}
          value={lName}
          onChange={(e) => setLName(e.target.value)}
          disabled={uiState.apiRequestLoading}
          error={nameError && !lName}
          required
        />

        <TextField
          id="email"
          variant="filled"
          label={"Email"}
          InputProps={{
            sx: {
              backgroundColor: "#fff",
            },
          }}
          sx={{ width: "300px", marginBottom: "8px", marginTop: "24px" }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={uiState.apiRequestLoading}
          error={emailError && !email}
          required
        />
        <TextField
          id="password"
          variant="filled"
          label={"Password"}
          type="password"
          InputProps={{
            sx: {
              backgroundColor: "#fff",
            },
          }}
          sx={{ width: "300px", marginBottom: "8px" }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={uiState.apiRequestLoading}
          error={passwordError && !password}
          required
        />

        <FormGroup sx={{ marginTop: 1, marginBottom: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                checked={eulaAccepted}
                onChange={onCheckboxChange}
              />
            }
            label={
              <Typography variant="subtitle2">
                I have read and accepted the{" "}
                <a href={eulaURL} target="_blank">
                  PeppyPrep End User License Agreement
                </a>
              </Typography>
            }
            sx={{ marginRight: 0 }}
          />
        </FormGroup>

        {!uiState.apiRequestLoading ? (
          <Button
            variant="contained"
            onClick={register}
            disabled={uiState.apiRequestLoading}
          >
            Register
          </Button>
        ) : (
          <div style={{ width: "40px", height: "40px", margin: "0 auto" }}>
            {uiState.apiRequestLoading && (
              <CircularProgress color="primary" sx={{ margin: "0 auto" }} />
            )}
          </div>
        )}

        <Typography variant="subtitle2" sx={{ marginTop: 1 }}>
          Already have an account? <Link to="/">Login</Link> now.
        </Typography>
      </div>
      <AlertComponent
        open={uiState.showToast}
        setOpen={setAlertOpen}
        alertProps={uiState}
      />
    </div>
  );
};
