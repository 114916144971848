import React, { useEffect } from "react";
import { logout } from "../services/firebase";
import { LoginRegContainer } from "../components/molecules/LoginRegContainer";
import { auth } from "../services/firebase";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store/store";
import { clearRedirectUrl, setToast } from "../store/state/ui";
import { toastProps, UiState } from "../types/ui";
import { AlertComponent } from "../components/molecules/AlertComponent";
import { CircularProgress, Typography } from "@mui/material";
import {
  getUserMe,
  login as apiLogin,
  logout as apiLogout,
} from "../services/ApiUserService";
import { APIErrorResponse, UserMeResponse } from "../types/responses";
import { resetApiUser, setApiUser } from "../store/state/user";
import { httpOnlyCookieExists } from "../helpers/utils";

export const Processing = () => {
  const dispatch = useDispatch();
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const { apiUser } = useSelector((x: AppState) => x.userReducer);
  const { apiRequestLoading, showToast, redirectUrl } = useSelector(
    (x: AppState) => x.uiReducer
  );
  const uiState: UiState = useSelector((x: AppState) => x.uiReducer);

  const setAlertPayload = (alertPayload: toastProps) => {
    dispatch({ type: setToast, payload: alertPayload });
  };

  const actionRedirect = () => {
    // Assume we're already checking if we're loading before calling this
    if (!!redirectUrl) {
      dispatch({ type: clearRedirectUrl });
      window.location.href = redirectUrl;
    }
  };

  useEffect(() => {
    document.title = "Processing - PeppyPrep";
  }, []);

  useEffect(() => {
    /**
     * Handle all cases for logging in or logging out and redirecting.
     */
    if (loading || apiRequestLoading) return;

    const hasServerTokens =
      httpOnlyCookieExists("access_token") &&
      httpOnlyCookieExists("refresh_token");

    if (apiUser.id && !user && !hasServerTokens) {
      // console.log('apiUser.id && !user && !hasServerTokens');
      // We shouldn't have an API user in this case
      dispatch({ type: resetApiUser });
      // navigate('/');
    } else if (hasServerTokens && !apiUser.id) {
      // console.log('hasServerTokens && !apiUser.id');
      // If we have refresh and access tokens, try
      // authenticating through PP server.
      getUserMe(
        (data: UserMeResponse) => {
          dispatch({ type: setApiUser, payload: data });
        },
        (_: APIErrorResponse) => {
          // Refresh token is expired, see if we get a new one via Firebase
          if (user) {
            apiLogin(
              user,
              (data: UserMeResponse) => {
                dispatch({ type: setApiUser, payload: data });
                // actionRedirect();
                // !redirectUrl && navigate("/dashboard");
              },
              (data: APIErrorResponse) => {
                console.error(data.detail);
                setAlertPayload({
                  toastMessage:
                    "Looks like we had some trouble signing you in, please contact support.",
                  toastSeverity: "error",
                  showToast: true,
                });
                // Log out of Firebase if we couldn't get user
                logout();
              }
            );
          } else {
            // Refresh token is expired or invalid
            navigate("/");
          }
        }
      );
    } else if (user && !apiUser.id) {
      // console.log('user && !apiUser.id');
      // If we got here we are missing either refresh or access token,
      // try logging in via Firebase.
      apiLogin(
        user,
        (data: UserMeResponse) => {
          dispatch({ type: setApiUser, payload: data });
        },
        (_: APIErrorResponse) => {
          // Refresh token is expired, see if we get a new one via Firebase
          if (user) {
            apiLogin(
              user,
              (data: UserMeResponse) => {
                dispatch({ type: setApiUser, payload: data });
                // actionRedirect();
                // !redirectUrl && navigate("/dashboard");
              },
              (data: APIErrorResponse) => {
                console.error(data.detail);
                setAlertPayload({
                  toastMessage:
                    "Looks like we had some trouble signing you in, please contact support.",
                  toastSeverity: "error",
                  showToast: true,
                });
                // Log out of Firebase if we couldn't get user
                logout();
              }
            );
          }

          // Refresh token is expired, do an API logout and return
          apiLogout(
            (data: APIErrorResponse) => {},
            () => {
              window.location.reload();
            }
          );
        }
      );
    } else {
      // console.log('else');
      // Now action redirects
      if (apiUser.id) {
        // If we haven't accepted EULA, redirect to EULA page
        if (!apiUser.eula_agreed) {
          navigate("/eula");
        } else {
          // All logged in, lets go to the next place!
          actionRedirect();
          !redirectUrl && navigate("/dashboard");
        }
      } else if (!user && !apiUser.id) {
        // All logged out
        actionRedirect();
        !redirectUrl && navigate("/");
      }
    }
  }, [user, loading, apiRequestLoading, apiUser]);

  return (
    <LoginRegContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          margin: "0 8px",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ marginTop: "32px", marginBottom: "12px" }}
        >
          One moment...
        </Typography>
        <CircularProgress color="primary" sx={{ margin: "0 auto" }} />
      </div>
      <AlertComponent open={showToast} alertProps={uiState} />
    </LoginRegContainer>
  );
};
