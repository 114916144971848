import { User as FirebaseUser } from "firebase/auth";
import { getUserToken } from "../helpers/auth";
import { httpOnlyCookieExists } from "../helpers/utils";
import { APIErrorResponse, isError } from "../types/responses";
import { APIRequestHeaders, RequestMethod } from "../types/types";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const STORE_APP_URL = process.env.REACT_APP_STORE_APP_URL;
export const API_USERS_ENDPOINT = "users/";
export const CMS_URL = process.env.REACT_APP_CMS_URL;

export const SERVER_COMMS_ERROR = "Can't communicate with PeppyPrep server";

export const IS_LOCAL = API_BASE_URL === "http://localhost:8000/";

export const HAS_REFRESH_AND_ACCESS_TOKENS =
  httpOnlyCookieExists("access_token") && httpOnlyCookieExists("refresh_token");

/**
 * Prepare request headers necessary to authenticate requests against the API.
 * If Firebase user is provided, we use that to add an auth token to the header,
 * otherwise we assume that we've already got a secure http only access token
 * cookie from PP API.
 *
 *
 * @param user If provided, sets an auth header with the Firebase user token.
 * @returns
 */
export const getApiRequestHeaders = async (user?: FirebaseUser) => {
  /**
   * Prepare headers with authorization for the current user to
   * do stuff with the backend API.
   */
  let headers: APIRequestHeaders = {
    "Content-Type": "application/json",
    Accept: "*/*",
    "Accept-Encoding": "gzip, deflate, br",
    Connection: "keep-alive",
    "Access-Control-Allow-Origin":
      process.env.REACT_APP_ORIGIN || "https://localhost:3002",
  };
  if (user) {
    const token = await getUserToken(user);
    headers.Authorization = "Bearer " + token;
  }
  return headers;
};

export const returnError = (error: any): APIErrorResponse => {
  if (!isError(error)) {
    return {
      detail: SERVER_COMMS_ERROR,
    };
  } else {
    return error;
  }
};

/**
 * A wrapper around fetch that will try to refresh an auth token
 * if we get a 401.  If the refresh is successful, we re-attempt the request.
 *
 * @param url Request URL
 * @param request RequestInit
 * @param onSuccess
 * @param onError
 * @returns
 */
export const fetchRefresh = async (
  url: string,
  request: RequestInit
): Promise<Response> => {
  const resp: Response = await fetch(url, request);
  if (resp.status === 401 && HAS_REFRESH_AND_ACCESS_TOKENS) {
    // Try refreshing token and retry original request if successful
    const refresh_resp = await refresh();
    if (refresh_resp.status === 200) return await fetch(url, request);
    // Couldn't refresh, return original error response
    return resp;
  }

  return resp;
};

export const refresh = async (): Promise<Response> => {
  const headers = await getApiRequestHeaders();

  const request = {
    method: "POST",
    headers: headers,
    credentials: "include",
  };

  // @ts-ignore
  return await fetch(API_BASE_URL + API_USERS_ENDPOINT + "refresh", request);
};

export const fetchWithActions = async (
  url: string,
  method: RequestMethod,
  fetchRefresh: boolean,
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  user?: FirebaseUser
) => {
  /**
   * TODO: Completely re-evaluate how your services are working...
   *
   * It'd probably make more sense to actually return payloads from these.
   */
  const headers = await getApiRequestHeaders(user);

  const request = {
    method: method,
    headers: headers,
    credentials: "include",
  };

  // @ts-ignore
  return await fetch(url, request);
};
