import { auth } from "../services/firebase";
import { User as FirebaseUser } from "firebase/auth";

export const getUserToken = async (firebaseUser: FirebaseUser | null) => {
  /**
   * Use this to retrieve a token to pass to the API.
   *
   * This will either return an existing valid token or handle refreshing.
   */
  let token: string | undefined = undefined;
  if (!!firebaseUser) {
    token = await auth.currentUser?.getIdToken();
  }
  return token;
};

export const getAuthProviders = (user: FirebaseUser | null | undefined) => {
  if (!user) {
    return [];
  }
  if (user?.providerData) {
    const providers = user.providerData.map((info) => info.providerId);
    return providers;
  }
  return [];
};
